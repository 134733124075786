<template>
  <div class="back">
    <v-row justify="center" fluid align="center">
      <v-col cols="12" sm="12" md="12" lg="6">
        <v-card class="pa-9 login-card">
          <v-card-text class="py-9">
            <v-row justify="center">
              <v-img
                src="@/assets/logo.png"
                max-height="150"
                max-width="250"
                alt=""
              ></v-img>
            </v-row>
            <v-row justify="center" class="mt-8">
              <h4>Login to VariLao System</h4>
            </v-row>
            <v-form ref="form" lazy-validation class="mt-8">
              <v-container>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      height="52px"
                      label="Email"
                      type="text"
                      v-model="users.email"
                      :rules="emailRules"
                      required
                      prepend-inner-icon="mdi-email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      label="Password"
                      type="password"
                      v-model="users.password"
                      :rules="passwordRules"
                      @keyup.enter="Submit"
                      required
                      prepend-inner-icon="mdi-account-key"
                    ></v-text-field>
                    <p class="errors">
                      {{ errors }}
                    </p>
                  </v-col>
                </v-row>

                <v-btn
                  block
                  class="login mt-6 py-6"
                  :loading="loading"
                  :disabled="loading"
                  @click="Submit"
                  >Login</v-btn
                >
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      timeout: 2000,
      users: {
        email: "",
        password: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be more than 6 characters",
      ],
      toast: {
        value: true,
        color: "success",
        msg: "Login Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "Something when wrong!",
      },
    };
  },

  methods: {
    ...mapActions({
      AdminLogin: "User/LoginUser",
    }),
    Submit() {
      if (this.$refs.form.validate() == true) {
        this.AdminSignIn();
      }
    },
    AdminSignIn() {
      this.loading = true;
      this.AdminLogin(this.users)
        .then(() => {
          setTimeout(() => {
            window.location.reload();
            this.loading = false;
            this.$store.commit("Toast_State", this.toast);
          }, 300);
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          }, 300);
        });
    },
  },
  computed: {
    ...mapGetters({
      errors: "User/ShowMsgErrors",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../public/scss/main.scss";
.login {
  background-color: $primary-color !important;
}
.login-card {
  box-shadow: none !important;
}
</style>